<template>
    <v-list nav dense>
        <v-list-item-group>

<!-- PRIMER MENU -->
            <v-list-group>
                <template v-slot:activator>
                    <v-list-item-icon>
                        <v-icon>ion ion-md-person</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Organización</v-list-item-title>
                    </v-list-item-content>
                </template>
                <router-link
                    class="black--text text--accent-4"
                    to="Persona"
                    active-class="blue--text text--accent-4"
                    v-if="persona.Rol==1"
                >
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>mdi-account-multiple</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{
                                $t("nav.Persona")
                            }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>

                <router-link
                    class="black--text text--accent-4"
                    to="UnidadAcademica"
                    active-class="blue--text text--accent-4"
                    v-if="persona.Rol<3"
                >
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>mdi-school</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{
                                $t("nav.UnidadAcademica")
                            }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>

                <router-link
                    class="black--text text--accent-4"
                    to="Rol"
                    active-class="blue--text text--accent-4"
                    v-if="persona.Rol==1"
                >
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>mdi-key</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{
                                $t("nav.Rol")
                            }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>

                <router-link
                    class="black--text text--accent-4"
                    to="Profile"
                    active-class="blue--text text--accent-4"
                >
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>mdi-account-circle</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{
                                $t("nav.Profile")
                            }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>


            </v-list-group>
<!-- SEGUNDO MENU -->
            <v-list-group v-if="persona.Rol<4">
                <template v-slot:activator>
                    <v-list-item-icon>
                        <v-icon>mdi mdi-mailbox</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Sugerencias</v-list-item-title>
                    </v-list-item-content>
                </template>

                

                <router-link
                    class="black--text text--accent-4"
                    to="TipoSugerencia"
                    active-class="blue--text text--accent-4"
                >
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>mdi mdi-message-image</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{
                                $t("nav.TipoSugerencia")
                            }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>

                 <router-link
                    class="black--text text--accent-4"
                    to="Categoria"
                    active-class="blue--text text--accent-4"
                    v-if="persona.Rol<3"
                >
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>mdi mdi-book-multiple-variant</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{
                                $t("nav.Categoria")
                            }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>

                <router-link
                    class="black--text text--accent-4"
                    to="Comentario"
                    active-class="blue--text text--accent-4"
                >
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>mdi-key</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{
                                $t("nav.Comentario")
                            }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>

            </v-list-group>

            <v-list-group v-if="persona.Rol<4">
                <template v-slot:activator>
                    <v-list-item-icon>
                        <v-icon>mdi mdi-format-page-break</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Reportes</v-list-item-title>
                    </v-list-item-content>
                </template>

                <router-link
                    class="black--text text--accent-4"
                    to="TipoReporte"
                    active-class="blue--text text--accent-4"
                >
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>mdi-note-text</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{
                                $t("nav.TipoReporte")
                            }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>
                <router-link
                    class="black--text text--accent-4"
                    to="TipoReporteVer"
                    active-class="blue--text text--accent-4"
                >
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>mdi-file-chart</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{
                                $t("nav.TipoReporteVer")
                            }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>
            </v-list-group>

            <router-link
                class="black--text text--accent-4"
                to="Sugerencia"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi mdi-bullhorn</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Sugerencia")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            
            
            <router-link
                class="black--text text--accent-4"
                to="Opinion"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>fas fa-home</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Opinion")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            
        </v-list-item-group>
    </v-list>
</template>

<script>
export default {
    name: "MenuVuetify",
    data() {
        return {
            msg: "MenuVuetify",
            persona: {},
        };

    },
    mounted() {
        this.persona = JSON.parse(localStorage.getItem('persona'));
    }

};
</script>