<template>
  <!-- Image and text  bg-transparent-->
  <div>
    
    <link href="/assets/estilos/bootstrap/css/bootstrap.min.css" rel="stylesheet">
    <link href="/assets/estilos/icofont/icofont.min.css" rel="stylesheet">
    <link href="/assets/estilos/boxicons/css/boxicons.min.css" rel="stylesheet">
    <link href="/assets/estilos/remixicon/remixicon.css" rel="stylesheet">
    <link href="/assets/estilos/owl.carousel/assets/owl.carousel.min.css" rel="stylesheet">
    <link href="/assets/estilos/venobox/venobox.css" rel="stylesheet">
    
    <link href="/assets/css/style2.css" rel="stylesheet">
    
    
    
    <!-- ======= Footer 
    ======= -->
    <footer id="footer">

        <div class="footer-top">

        <div class="container">

            <div class="row  justify-content-center">
            <div class="col-lg-6">
                <h3> - EMI - </h3>
                <h4 class="mt-5 text-white"> <i> </i> </h4>
            </div>
            </div>

            <!--<div class="social-links display-4">
            <a href="#" class="twitter"><i class="bx:bxl-twitter"></i></a>
            <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
            <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
            <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a>
            <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a>
            </div>-->

            <div class="row">
                <div class="col">
                    <p style="color: #F4B909">DIRECCIONES DE POSGRADO</p>
                    <ul  align="left">
                        <li>
                            <a href="https://lapaz.posgrado.emi.edu.bo/" style="color: white; font-weight: bold;" target="_blank" align="left">LA PAZ</a>
                        </li>
                        <li>
                            <a href="https://cochabamba.posgrado.emi.edu.bo/" style="color: white; font-weight: bold;" target="_blank"  align="left">COCHABAMBA</a>
                        </li>
                        <li>
                            <a href="https://santacruz.posgrado.emi.edu.bo/" style="color: white; font-weight: bold;" target="_blank"  align="left">SANTA CRUZ</a>
                        </li>
                        <li>
                            <a href="https://riberalta.posgrado.emi.edu.bo/" style="color: white; font-weight: bold;" target="_blank"  align="left">RIBERALTA</a>
                        </li>
                        
                    </ul>
                </div>
                <div class="col">
                    <p style="color: #F4B909">LA PAZ</p>
                    <p style="color: white; font-weight: bold;"  align="left">
                        EMI Irpavi
                        <br>
                        Telf: 2775536 - 2799505
                        <br>
                        Fax: 2773774
                        <br>
                        EMI Central Av. Arce No. 2642
                        <br>
                        Telf. 2432266 - 2431641 - 2435285
                    </p>
                    <p>
                        <a href="mailto:lapaz@adm.emi.edu.bo" style="color: white; font-weight: bold;">lapaz@adm.emi.edu.bo</a>
                    </p>
                </div>
                <div class="col">
                    <p style="color: #F4B909">COCHABAMBA</p>
                    <p style="color: white; font-weight: bold;"  align="left">
                        Av. 23 de Marzo, Zona Muyurina
                        <br>
                        Telf: 4531133 - 4530361
                        <br>
                        Fax: 4530361
                        <br>
                        Av. Lanza entre Oruro y La Paz
                        <br>
                        Telf: 4521844
                    </p>
                    <p>
                        <a href="mailto:cochabamba@adm.emi.edu.bo" style="color: white; font-weight: bold;">cochabamba@adm.emi.edu.bo</a>
                    </p>
                
                </div>
                <div class="col">
                    <p style="color: #F4B909">SANTA CRUZ</p>
                    <p style="color: white; font-weight: bold;" align="left">
                        Tercer Anillo Radial 13
                        <br>
                        Telf: 3527431 - 3579545
                        <br>
                        Fax: 3579545
                    </p>
                    <p>
                        <a href="mailto:santacruz@adm.emi.edu.bo" style="color: white; font-weight: bold;">santacruz@adm.emi.edu.bo</a>
                    </p>
                
                </div>
                <div class="col">
                    <p style="color: #F4B909">RIBERALTA</p>
                    <p style="color: white; font-weight: bold" align="left">
                        Nicanor Salvatierra N° 154
                        <br>
                        Barrio La Cruz
                        <br>
                        Telf: 8524373
                    </p>
                    <p>
                        <a href="mailto:riberalta@adm.emi.edu.bo" style="color: white; font-weight: bold;">riberalta@adm.emi.edu.bo</a>
                    </p>
                
                </div>
                <div class="col">
                    <p style="color: #F4B909">TRÓPICO</p>
                    <p style="color: white; font-weight: bold;">
                        Calle Germán Bush / OTB Paraíso
                        <br>
                        Distrito N° 9 - Shinahota
                        <br>
                        Cel.: 71291823 - 71291829
                        
                    </p>
                    <p>
                        <a href="mailto:tropico@adm.emi.edu.bo" style="color: white; font-weight: bold;">tropico@adm.emi.edu.bo</a>
                    </p>
                </div>
            </div>


        </div>
        </div>

        <div class="container footer-bottom clearfix">
        <div class="copyright">
            &copy; Copyright <strong><span>DNTIC</span></strong>. All Rights Reserved
        </div>
        <div class="credits">

            Designed by <a href="#">Escuela Militar de Ingeniería - DNTIC</a>
        </div>
        </div>
    </footer>
    </div>
  <!-- End Header -->
</template>

<script>
export default {
    name: "FooterExterno",
    data() {
        return {
        MenuExterno: {},
                errorBag: {},
                nuevaPersona:{},
                loginCredenciales: {},
        };
    },
    mounted() {

    },

	methods: {

    }
};
</script>