import Vue from 'vue'
import Router from 'vue-router'
import LoginPage from '@/pages/Login/LoginPage.vue'
import UnidadAcademicaPage from '@/pages/UnidadAcademica/UnidadAcademicaPage.vue'
import RolPage from '@/pages/Rol/RolPage.vue'
import PersonaPage from '@/pages/Persona/PersonaPage.vue'
import PrincipalPage from '@/pages/Principal/PrincipalPage.vue'
import ProfilePage from '@/pages/Profile/ProfilePage.vue'
import TipoReportePage from '@/pages/TipoReporte/TipoReportePage.vue'
import TipoReporteVerPage from '@/pages/TipoReporteVer/TipoReporteVerPage.vue'
import CategoriaPage from '@/pages/Categoria/CategoriaPage.vue'
import ComentarioPage from '@/pages/Comentario/ComentarioPage.vue'
import SugerenciaPage from '@/pages/Sugerencia/SugerenciaPage.vue'
import TipoSugerenciaPage from '@/pages/TipoSugerencia/TipoSugerenciaPage.vue'
import OpinionPage from '@/pages/Opinion/OpinionPage.vue'


Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'PrincipalPage',
      component: PrincipalPage
    },
    {
      path: '/UnidadAcademica',
      name: 'Unidad Academica',
      component: UnidadAcademicaPage
    },
    {
      path: '/Rol',
      name: 'Rol',
      component: RolPage
    },
    {
      path: '/Persona',
      name: 'Persona',
      component: PersonaPage
    },
    {
      path: '/Profile',
      name: 'Perfil',
      component: ProfilePage
    },
    {
      path: '/Login',
      name: 'Login',
      component: LoginPage
    },
    {
      path: '/TipoReporte',
      name: 'TipoReporte',
      component: TipoReportePage
    },
    {
      path: '/TipoReporteVer',
      name: 'TipoReporteVer',
      component: TipoReporteVerPage
    },
    {
      path: '/Categoria',
      name: 'Categoria',
      component: CategoriaPage
    },
    {
      path: '/Comentario',
      name: 'Comentario',
      component: ComentarioPage
    },
    {
      path: '/Sugerencia',
      name: 'Sugerencia',
      component: SugerenciaPage
    },
    {
      path: '/TipoSugerencia',
      name: 'TipoSugerencia',
      component: TipoSugerenciaPage
    },
    {
      path: '/Opinion',
      name: 'Opinion',
      component: OpinionPage
    }
  ]
})