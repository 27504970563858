var axios = require('axios');

export default class MainService {

    constructor() {
        var token = JSON.parse(localStorage.getItem('token'));
        axios.defaults.baseURL = process.env.VUE_APP_MAIN_SERVICE;
        if (token) {
            axios.defaults.headers.common = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }else{
            axios.defaults.headers.common = {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    }

    getToken() {
        return axios.defaults.headers.common;
    }

    login(item) {
        return axios.post('api/login', item);
    }

    uploadFile(item) {
        return axios.post('api/uploadFile', item);
    }

    loginToken365(item) {
        return axios.post('api/loginToken365', item);
    }

    indexPersona() {
        return axios.defaults.baseURL + 'api/Persona/index';
    }

    showPersona(id) {
        return axios.get('api/Persona/show?id=' + id);
    }

    listPersona() {
        return axios.get('api/Persona/list');
    }

    storePersona(item) {
        return axios.post('api/Persona/store', item);
    }

    destroyPersona(item) {
        return axios.post('api/Persona/destroy', item);
    }

    changePassword(item) {
        return axios.post('api/Persona/changePassword', item);
    }

    showUnidadAcademica(id) {
        return axios.get('api/UnidadAcademica/show?id=' + id);
    }

    indexUnidadAcademica() {
        return axios.defaults.baseURL + 'api/UnidadAcademica/index';
    }

    listUnidadAcademica() {
        return axios.get('api/UnidadAcademica/list');
    }

    storeUnidadAcademica(item) {
        return axios.post('api/UnidadAcademica/store', item);
    }

    destroyUnidadAcademica(item) {
        return axios.post('api/UnidadAcademica/destroy', item);
    }

    showRol(id) {
        return axios.get('api/Rol/show?id=' + id);
    }

    indexRol() {
        return axios.defaults.baseURL + 'api/Rol/index';
    }

    listRol() {
        return axios.get('api/Rol/list');
    }

    storeRol(item) {
        return axios.post('api/Rol/store', item);
    }

    destroyRol(item) {
        return axios.post('api/Rol/destroy', item);
    }

    //TipoReporte
    showTipoReporte(id) {
        return axios.get('api/TipoReporte/show?id=' + id);
    }

    indexTipoReporte() {
        return axios.defaults.baseURL + 'api/TipoReporte/index';
    }

    listTipoReporte() {
        return axios.get('api/TipoReporte/list');
    }

    storeTipoReporte(item) {
        return axios.post('api/TipoReporte/store', item);
    }

    destroyTipoReporte(item) {
        return axios.post('api/TipoReporte/destroy', item);
    }

    generateTipoReporte(item) {
        return axios.post('api/TipoReporte/generate', item);
    }

    //Categoria
    showCategoria(id) {
        return axios.get('api/Categoria/show?id=' + id);
    }

    indexCategoria() {
        return axios.defaults.baseURL + 'api/Categoria/index';
    }

    listCategoria() {
        return axios.get('api/Categoria/list');
    }

    storeCategoria(item) {
        return axios.post('api/Categoria/store', item);
    }

    destroyCategoria(item) {
        return axios.post('api/Categoria/destroy', item);
    }

    //Comentario
    showComentario(id) {
        return axios.get('api/Comentario/show?id=' + id);
    }

    indexComentario() {
        return axios.defaults.baseURL + 'api/Comentario/index';
    }

    listComentario(id) {
        return axios.get('api/Comentario/list', {params:{idSugerencia:id}});
    }

    storeComentario(item) {
        return axios.post('api/Comentario/store', item);
    }

    destroyComentario(item) {
        return axios.post('api/Comentario/destroy', item);
    }

    //Sugerencia
    showSugerencia(id) {
        return axios.get('api/Sugerencia/show?id=' + id);
    }

    indexSugerencia() {
        return axios.defaults.baseURL + 'api/Sugerencia/index';
    }

    listSugerencia() {
        return axios.get('api/Sugerencia/list');
    }

    storeSugerencia(item) {
        return axios.post('api/Sugerencia/store', item);
    }

    destroySugerencia(item) {
        return axios.post('api/Sugerencia/destroy', item);
    }

    //TipoSugerencia
    showTipoSugerencia(id) {
        return axios.get('api/TipoSugerencia/show?id=' + id);
    }

    indexTipoSugerencia() {
        return axios.defaults.baseURL + 'api/TipoSugerencia/index';
    }

    listTipoSugerencia() {
        return axios.get('api/TipoSugerencia/list');
    }

    storeTipoSugerencia(item) {
        return axios.post('api/TipoSugerencia/store', item);
    }

    destroyTipoSugerencia(item) {
        return axios.post('api/TipoSugerencia/destroy', item);
    }
    
    //Example
    showExample(id) {
        return axios.get('api/Example/show?id=' + id);
    }

    indexExample() {
        return axios.defaults.baseURL + 'api/Example/index';
    }

    listExample() {
        return axios.get('api/Example/list');
    }

    storeExample(item) {
        return axios.post('api/Example/store', item);
    }

    destroyExample(item) {
        return axios.post('api/Example/destroy', item);
    }
}