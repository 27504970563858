import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "SugerenciaPage",
    data() {
        let ss = new MainService();
        return {
            msg: "SugerenciaPage",
            ss: ss,
            ajax: {
                "url": ss.indexSugerencia(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Sugerencia', name: 'Sugerencia', title: 'Sugerencia' },
                { data: 'Categoria', name: 'c.Categoria', title: 'Categoría' },
                { data: 'UnidadAcademica', name: 'ua.UnidadAcademica', title: 'Unidad Académica' },
                { data: 'Persona', name: 'p.Persona', title: 'Persona', render: function(data, type, row){
                    if(row.Anonimo == true){
                        return "Anónimo"
                    }else{
                        return row.Persona;
                    }
                }},
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            sugerencias: [],
            sugerencia: {},
            persona:{},
            comentario: {},
            comentarios: [],
            isLoading: false,
            isLoadingFile:false,
            errorBag: {},
            categorias: [],
            tipoSugerencias: [],
            unidadAcademicas: [],
            

            loginRequest: {},
			rutaApp:process.env.VUE_APP_MAIN_SERVICE,
			
        };
    },
    methods: {

        // cargado de imagenes
        loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            );
                            this.sugerencia.Imagen = result.data.data;

                            
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },
        
        // Categoria
        getCategoria() {
            this.ss.listCategoria().then(
                (result) => {
                    let response = result.data;
                    this.categorias = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        // tipo sugerencia
        getTipoSugerencia() {
            this.ss.listTipoSugerencia().then(
                (result) => {
                    let response = result.data;
                    this.tipoSugerencias = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        // unidad Academica
        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadAcademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        
        // sugerencia
        newSugerencia() {
            this.sugerencia = {};
            this.$refs['frm-sugerencia'].show();
        },
        showSugerencia(id) {
            this.isLoading=true;
            this.ss.showSugerencia(id).then(
                (result) => {
                    let response = result.data;
                    this.sugerencia = response.data;
                    this.$refs['view-sugerencia'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editSugerencia() {
            this.$refs['frm-sugerencia'].show();
            this.$refs['view-sugerencia'].hide();
        },
        cancelSugerencia() {
            if (this.sugerencia.id) {
                this.$refs['view-sugerencia'].show();
            }
            this.$refs['frm-sugerencia'].hide();
        },
        saveSugerencia() {
            this.ss.storeSugerencia(this.sugerencia).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-sugerencia'].hide();
                    this.$refs['datatable-sugerencia'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteSugerencia() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroySugerencia(this.sugerencia)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-sugerencia'].hide();
                                this.$refs['datatable-sugerencia'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },

        // Comentario
        getComentario() {
            this.ss.listComentario().then(
                (result) => {
                    let response = result.data;
                    this.comentarios = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newComentario() {
            this.comentario = {};
            this.$refs['frm-comentario'].show();
        },
        showComentario(id) {
            this.isLoading=true;
            this.ss.showComentario(id).then(
                (result) => {
                    let response = result.data;
                    this.comentario = response.data;
                    this.$refs['view-comentario'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        cancelComentario() {
            if (this.comentario.id) {
                this.$refs['view-comentario'].show();
            }
            this.$refs['frm-comentario'].hide();
        },
        saveComentario() {
            this.ss.storeComentario(this.comentario).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-comentario'].hide();
                    this.$refs['datatable-comentario'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteComentario() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyComentario(this.comentario)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-comentario'].hide();
                                this.$refs['datatable-comentario'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-Sugerencia').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showSugerencia(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
          this.$router.push('/Login');
        }else{
            this.sugerencia.Persona = persona.id;
            this.getCategoria();
            this.getTipoSugerencia();
            this.getUnidadAcademica();
            this.getComentario();
        }
    }
};
