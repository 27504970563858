import MainService from "@/services/MainService.js";
import Vue from 'vue'
import VueAxios from 'vue-axios'
import axios from 'axios';
import msal from 'vue-msal'
import Loader from "@/components/Loader";

import MenuExterno from "@/components/MenuExterno";
import FooterExterno from "@/components/FooterExterno";

Vue.use(VueAxios, axios)

Vue.use(msal, {
	auth: {
		clientId: process.env.VUE_APP_MSAL_CLIENT_ID,
		tenantId: process.env.VUE_APP_MSAL_TENANT_ID,
		redirectUri: process.env.VUE_APP_MSAL_REDIRECT_URI,
		rutaApp:process.env.VUE_APP_MAIN_SERVICE

	}
});
window.$ = window.jQuery = require("jquery");

export default {
    name: "OpinionPage",
    data() {
        let ss = new MainService();
        return {
            msg: "OpinionPage",
            ss: ss,
            ajax: {
                "url": ss.indexSugerencia(),
                headers: ss.getToken(),
            },
            
            sugerencias: [],
            sugerencia: {},

            categorias:[],
            categoria:{},

            unidadAcademicas:[],
            unidadAcademica:{},

            tipoSugerencias: [],
            tipoSugerencia: {},

            persona:{},
            personas:[],

            comentario:{},
            comentarios:[],

            isLoading: false,
            isLoadingFile:false,

            loginRequest: {},
			errorBag: {},
			slide: 0,
			sliding: null,
			rutaApp:process.env.VUE_APP_MAIN_SERVICE,
			auth:{},
			datosInfo:{},
            nroSugerencias:3
        };
    },
    
	props: {
		msg: String
	},
    methods: {
        
        // Sugerencia 
        getSugerencia() {
            this.ss.listSugerencia().then(
                (result) => {
                    let response = result.data;
                    this.sugerencias = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        

        newSugerencia() {
            this.sugerencia = {};
            this.$refs['frm-sugerencia'].show();
        },
        showSugerencia(id) {
            this.isLoading=true;
            this.ss.showSugerencia(id).then(
                (result) => {
                    let response = result.data;
                    this.sugerencia = response.data;
                    this.$refs['view-sugerencia'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editSugerencia() {
            this.$refs['frm-sugerencia'].show();
            this.$refs['view-sugerencia'].hide();
        },
        cancelSugerencia() {
            if (this.sugerencia.id) {
                this.$refs['view-sugerencia'].show();
            }
            this.$refs['frm-sugerencia'].hide();
        },
        saveSugerencia() {
            this.ss.storeSugerencia(this.sugerencia).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-sugerencia'].hide();
                    this.$refs['datatable-sugerencia'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteSugerencia() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroySugerencia(this.sugerencia)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-sugerencia'].hide();
                                this.$refs['datatable-sugerencia'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },

        // Categoria
        getCategoria() {
            this.ss.listCategoria().then(
                (result) => {
                    let response = result.data;
                    this.categorias = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        // Tipo de Sugerencia
        getTipoSugerencia() {
            this.ss.listTipoSugerencia().then(
                (result) => {
                    let response = result.data;
                    this.tipoSugerencias = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        // Unidad Academica
        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadAcademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        // Comentarios

        getComentario() {
            this.ss.listComentario().then(
                (result) => {
                    let response = result.data;
                    this.comentarios = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getComentarioSugerencia() {

            let idSugerencia = this.sugerencia.id;
            this.ss.listComentario(idSugerencia).then(
                (result) => {
                    let response = result.data;
                    this.comentarios = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        newComentario() {
            this.comentario = {};
            this.$refs['frm-comentario'].show();
        },
        showComentario(id) {
            this.isLoading=true;
            this.ss.showComentario(id).then(
                (result) => {
                    let response = result.data;
                    this.comentario = response.data;
                    this.$refs['view-comentario'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        cancelComentario() {
            if (this.comentario.id) {
                this.$refs['view-comentario'].show();
            }
            this.$refs['frm-comentario'].hide();
        },
        saveComentario() {
            console.log(this.comentario, "este es el comentario")
            this.ss.storeComentario({Comentario:this.comentario.Comentario, Sugerencia: this.sugerencia.id}).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.showSugerencia(this.sugerencia.id)
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-comentario'].hide();
                    // this.$refs['datatable-comentario'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteComentario() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyComentario(this.comentario)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-comentario'].hide();
                                this.$refs['datatable-comentario'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },

        // Cargado de imagenes

        loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            );
                            this.sugerencia.Imagen = result.data.data;

                            
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },
    },
    components: {
        Loader,
        MenuExterno,
	FooterExterno,
    },
    mounted() {
        this.auth = JSON.parse(localStorage.getItem("persona"));
		window.scrollTo(0, 0);
		
		this.getSugerencia();
        this.getCategoria();
        this.getTipoSugerencia();
        this.getSugerencia();
        this.getUnidadAcademica();
        this.getComentario();
         
    }
};
